import React from "react";
import { Box, Button, Collapsible, Layer, ResponsiveContext } from "grommet";
import { FormClose } from "grommet-icons";
import { NewPresentForm } from "../presents";

export const Sidebar = ({ showSidebar, setShowSidebar }) => {
  const size = React.useContext(ResponsiveContext);

  const formNewPresent = <NewPresentForm />;

  return !showSidebar || size !== "small" ? (
    <Collapsible direction="horizontal" open={showSidebar}>
      <Box
        flex
        width="medium"
        background="light-2"
        elevation="small"
        align="center"
        justify="start"
      >
        {formNewPresent}
      </Box>
    </Collapsible>
  ) : (
    <Layer>
      <Box
        background="light-2"
        tag="header"
        justify="end"
        align="center"
        direction="row"
      >
        <Button icon={<FormClose />} onClick={() => setShowSidebar(false)} />
      </Box>
      <Box fill background="light-2" align="center" justify="center">
        {formNewPresent}
      </Box>
    </Layer>
  );
};
