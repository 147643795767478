import React from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  Form,
  FormField,
  TextInput,
  TextArea,
  Heading,
} from "grommet";
import { addPresent } from "./thunk";

export const NewPresentForm = () => {
  const dispatch = useDispatch();

  const handleClick = ({ value }) => {
    dispatch(addPresent({ formValues: value }));
  };

  return (
    <Form onSubmit={handleClick}>
      <Heading level="3">Ajoutez un cadeau:</Heading>
      <FormField name="name" htmlfor="name" label="Nom">
        <TextInput id="name" name="name" />
      </FormField>
      <FormField name="description" htmlfor="description" label="Description">
        <TextArea id="description" name="description" />
      </FormField>
      <FormField name="price" htmlfor="price" label="Prix">
        <TextInput id="price" name="price" />
      </FormField>
      <Box direction="row" gap="medium">
        <Button type="submit" primary label="sauvegarder" />
        <Button type="reset" label="annuler" />
      </Box>
    </Form>
  );
};
