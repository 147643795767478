export const addPresent = ({ formValues }) => (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();
  const firebase = getState().firebase;

  firestore
    .collection("presents")
    .add({
      ...formValues,
      ownerFirstName: firebase.profile.firstName,
      ownerLastName: firebase.profile.lastName,
      ownerId: firebase.auth.uid,
    })
    .then(() => {
      alert("Saved");
    });
};

export const deletePresent = (id) => (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();

  firestore
    .collection("presents")
    .doc(id)
    .delete()
    .then(() => {
      alert("deleted");
    })
    .catch((error) => {
      alert(error);
    });
};

export const selectPresent = ({ presentId, ownerId }) => (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();
  const firebase = getState().firebase;

  firestore
    .collection("buyers")
    .add({
      presentId,
      ownerId,
      buyerName: firebase.profile.firstName,
      buyerId: firebase.auth.uid,
    })
    .then(() => {
      alert("Saved");
    })
    .catch((error) => {
      alert(error);
    });
};

export const unSelectPresent = (id) => (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();

  firestore
    .collection("buyers")
    .doc(id)
    .delete()
    .then(() => {
      alert("unselected");
    })
    .catch((error) => {
      alert(error);
    });
};
