import React, { useState } from "react";
import { Box, Button, Form, FormField, Text, TextInput } from "grommet";
import { useFirebase } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { Redirect } from "@reach/router";

export const Login = () => {
  const firebase = useFirebase();
  const auth = useSelector((state) => state.firebase.auth);

  const [error, setError] = useState("");

  const handleClick = ({ value }) => {
    const { email, password } = value;
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        setError("");
      })
      .catch((error) => {
        setError(error);
      });
  };

  return (
    <Box direction="row" flex align="center" justify="center">
      <Box flex align="center" justify="center">
        {!auth.isEmpty && <Redirect to="/" noThrow />}
        {/* <Button
          label="Sign In with Google"
          primary
          onClick={() => {
            const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
            firebase
              .auth()
              .signInWithPopup(googleAuthProvider)
              .then((response) => {
                console.log(response);
              });
          }}
        /> */}

        {error && <Text color="status-critical">{`${error}`}</Text>}

        <Form onSubmit={handleClick}>
          <FormField name="email" htmlfor="email" label="Courriel">
            <TextInput required id="email" name="email" />
          </FormField>
          <FormField name="password" htmlfor="password" label="Mot de passe">
            <TextInput required type="password" id="password" name="password" />
          </FormField>
          <Box direction="row" gap="medium">
            <Button type="submit" primary label="Envoyer" />
            <Button type="reset" label="Annuler" />
          </Box>
        </Form>
      </Box>
    </Box>
  );
};
