import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
  apiKey: "AIzaSyALbEO1e9hIl4ZnrppmQ5m6kbDDfstRyAY",
  authDomain: "christmas-5721b.firebaseapp.com",
  databaseURL: "https://christmas-5721b.firebaseio.com",
  projectId: "christmas-5721b",
  storageBucket: "christmas-5721b.appspot.com",
  messagingSenderId: "163676543871",
  appId: "1:163676543871:web:78f42421e13b40bac9584a",
  measurementId: "G-WR5F4Z3CR1",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// firebase.analytics();

firebase.firestore().settings({ timestampsInSnapshots: true });

// react-redux-firebase config
const reduxReactFirebaseConfig = {
  userProfile: "users",
  useFirestoreForProfile: true,
  // enableClaims: true // Get custom claims along with the profile,
  attachAuthIsReady: true,
};

export { firebase, reduxReactFirebaseConfig };
