import React from "react";
import { Box, Button, Heading, Nav } from "grommet";
import { AddCircle, Logout } from "grommet-icons";
import { useSelector } from "react-redux";
import { useFirebase } from "react-redux-firebase";

export const AppBar = ({ showSidebar, setShowSidebar }) => {
  const auth = useSelector((state) => state.firebase.auth);

  const firebase = useFirebase();

  const handleLogout = () => {
    firebase.auth().signOut();
    setShowSidebar(false);
  };

  return (
    <Box
      tag="header"
      direction="row"
      align="center"
      justify="between"
      background="brand"
      pad={{ left: "medium", right: "small", vertical: "small" }}
      elevation="medium"
      style={{ zIndex: "1" }}
    >
      <Heading level="3" margin="none">
        🎄🎄🎄 NOËL 🎄🎄🎄
      </Heading>

      <Box direction="row">
        <Nav direction="row" background="brand" align="end">
          {!auth.isEmpty && (
            <>
              <Button
                icon={<AddCircle color="#f8b229" />}
                onClick={() => setShowSidebar(!showSidebar)}
              />
              <Button
                icon={<Logout color="#f8b229" />}
                onClick={handleLogout}
              />
            </>
          )}
        </Nav>
      </Box>
    </Box>
  );
};
